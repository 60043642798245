import React, {
  useEffect,
  memo,
  useCallback,
  useContext,
  useState,
} from 'react'
import { Form, Field } from 'react-final-form'
import _get from 'lodash/get'

import {
  StyledLoginFormWrapper,
  StyledLoginFormContent,
  StyledLoginFormAction,
} from './styles'
import { StyledForm, StyledField, StyledFormLoader } from '../styles'
import Container from '../../Container'
import Input from '../../Field/Input/Input'
import CheckBox from '../../Field/CheckBox/CheckBox'
import Button from '../../Buttons/Button/Button'
import { FIELD_TYPES } from '../../../constants/fieldTypes'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import AuthorizationContext from '../../../common/authorization/Context'
import { Body } from '../../Typography/Typography'

const INITIAL_VALUES = { email: '', password: '' }

const LoginForm = () => {
  const { login, error: authError } = useContext(AuthorizationContext)
  const [error, setError] = useState(authError)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true)
      setError('')

      try {
        await login(data)
      } catch (e) {
        const message = _get(e, 'response.data.data.message')

        if (message) {
          setError(message)
        }
      }
      setIsLoading(false)
    },
    [login],
  )

  useEffect(() => {
    setError(authError)
  }, [authError])

  return (
    <StyledLoginFormWrapper>
      {isLoading && <StyledFormLoader />}
      <Form
        onSubmit={onSubmit}
        initialValues={INITIAL_VALUES}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit} isLoading={isLoading}>
            {!!error && (
              <Container bg="error" p="0.75rem 1rem" mb="0.75rem">
                <Body color="white" opacity="1">
                  {error}
                </Body>
              </Container>
            )}
            <StyledLoginFormContent>
              <StyledField>
                <Field id="username" name="username">
                  {({ input }) => (
                    <Input
                      fieldType={FIELD_TYPES.light}
                      label="Username/Email"
                      placeholder="Type your name or email"
                      {...input}
                    />
                  )}
                </Field>
              </StyledField>
              <StyledField>
                <Field id="password" name="password">
                  {({ input }) => (
                    <Input
                      label="Password"
                      fieldType={FIELD_TYPES.light}
                      inputType="password"
                      placeholder="Type your password"
                      {...input}
                    />
                  )}
                </Field>
              </StyledField>
              <Container mt="1.5rem">
                <Field
                  component={CheckBox}
                  id="remember-me"
                  name="remember-me"
                  label="Remember Me"
                />
              </Container>
            </StyledLoginFormContent>
            <StyledLoginFormAction>
              <Button
                type="submit"
                aria-label="Submit"
                submit
                decor
                btnType={BUTTON_TYPES.secondary}
              >
                Login
              </Button>
            </StyledLoginFormAction>
          </StyledForm>
        )}
      />
    </StyledLoginFormWrapper>
  )
}

export default memo(LoginForm)
