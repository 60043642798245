import React, { useContext, useEffect } from 'react'

import { navigate } from 'gatsby'

import SEO from '../../components/Seo'
import Container from '../../components/Container'
import TruncatedHero from '../../components/Heros/TruncatedHero/TruncatedHero'
import { Heading2 } from '../../components/Typography/Typography'
import InfoBox from '../../components/InfoBox/InfoBox'
import LoginForm from '../../components/Forms/LoginForm/LoginForm'
import { TRUNCATED_HERO_TYPES } from '../../constants/truncatedHeroTypes'
import { generateHero } from '../utils'
import FlexContentBlocks from '../queries/FlexContentBlocks'
import { URL_PATHS } from '../../constants/urlPaths'
import AuthorizationContext from './Context'

const Login = ({ data }) => {
  const { isChecked, isLoggedIn } = useContext(AuthorizationContext)

  const page = data.wp.page?.viploginAcf
  const seoData = data.wp.page?.seo

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  const contentBlocks = page?.contentBlocks?.content

  useEffect(() => {
    if (isChecked && isLoggedIn) {
      navigate(URL_PATHS.VIP_WELCOME)
    }
  }, [isChecked, isLoggedIn])

  return (
    <>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={[heroColorMobile, '', heroColorDesktop]}
      >
        <TruncatedHero
          positionImg="50% 0%"
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroSlantType={slantDirection || 'right'}
          heroImages={heroImages}
          opacityImg={0.9}
          gradient={hasBgGradient ? bgGradient : null}
          long={isHeroHigh}
        >
          <Container mt={['-10rem', '', '']} mb={['', '', '3.75rem']}>
            <InfoBox
              bg={infoBoxBg}
              bgOpacity={infoBoxOpacity}
              title={
                <Heading2 as="h1">
                  <strong>VIP</strong> Login
                </Heading2>
              }
              content={<LoginForm />}
              full
              alt
            />
          </Container>
        </TruncatedHero>
      </Container>

      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
    </>
  )
}

export default Login
