import styled from 'styled-components'

import { customMedia } from '../../../common/MediaQueries'
import { StyledFormContent } from '../styles'

const StyledLoginFormWrapper = styled.div`
  margin-top: -1.5rem;
  background: inherit;
`

const StyledLoginFormContent = styled(StyledFormContent)`
  margin-bottom: 2.25rem;
`

const StyledLoginFormAction = styled.div`
  @media ${customMedia.maxPhone} {
    button {
      width: 100%;
    }
  }
`

export { StyledLoginFormWrapper, StyledLoginFormContent, StyledLoginFormAction }
