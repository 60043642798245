import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Login from '../common/authorization/Login'

const VipPage = ({ data }) => (
  <Layout>
    <Login data={data} />
  </Layout>
)

export default VipPage

export const query = graphql`
  query {
    wp {
      page(id: "vip-login", idType: URI) {
        seo {
          ...seoFragment
        }
        viploginAcf {
          heroFields {
            slantDirection
            heroHeight
            boxBackgroundOpacity
            boxBackgroundColor
            bgMobile
            bgGradient
            bgDesktop
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Viploginacf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                quote
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Viploginacf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
