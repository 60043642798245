import styled, { keyframes, css } from 'styled-components'

import media from '../../common/MediaQueries'

const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledForm = styled.form`
  width: 100%;

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`

const StyledField = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.875rem;
`

const StyledFieldHalf = styled(StyledField)`
  @media ${media.tablet} {
    width: calc(50% - 0.5rem);
  }
`

const StyledFormHeader = styled.div`
  margin-bottom: 1.25rem;
`

const StyledFormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledFormSentMsg = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const StyledFormLoader = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 5rem;
  height: 5rem;

  &::after {
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    border: 0.375rem solid ${({ theme }) => theme.colors.yellow};
    border-color: ${({ theme }) => theme.colors.yellow} transparent
      ${({ theme }) => theme.colors.yellow} transparent;
    animation: ${rotate360} 1.5s linear infinite;
  }
`

export {
  StyledForm,
  StyledField,
  StyledFieldHalf,
  StyledFormHeader,
  StyledFormContent,
  StyledFormSentMsg,
  StyledFormLoader,
}
