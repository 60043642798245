import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledCheckBoxGroup,
  StyledCheckBoxLabel,
  StyledCheckBoxInput,
} from './styles'

const CheckBox = ({ label, id, name }) => (
  <StyledCheckBoxGroup>
    <StyledCheckBoxInput {...{ id, name }} type="checkbox" />
    <StyledCheckBoxLabel htmlFor={id}>{label}</StyledCheckBoxLabel>
  </StyledCheckBoxGroup>
)

export default CheckBox

CheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}
