import styled from 'styled-components'

import {
  StyledChooseFieldGroup,
  StyledChooseFieldLabel,
  StyledChooseFieldInput,
} from '../styles'

const StyledCheckBoxGroup = styled(StyledChooseFieldGroup)``

const StyledCheckBoxLabel = styled(StyledChooseFieldLabel)`
  &::after {
    top: 50%;
    left: 0.4375rem;
    width: 0.25rem;
    height: 0.5rem;
    border: solid ${({ theme }) => theme.colors.white};
    border-width: 0 0.125rem 0.125rem 0;
    transform: translateY(-50%) rotate(45deg);
  }
`

const StyledCheckBoxInput = styled(StyledChooseFieldInput)``

export { StyledCheckBoxGroup, StyledCheckBoxLabel, StyledCheckBoxInput }
